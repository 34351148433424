import { AUTH_SESSION } from 'Reducers/auth/types';
import { INITIALIZATION_ERROR } from 'Reducers/nav/types';

import { getAuthSession } from 'Utilities/session';
import { store } from 'Utilities/store';

import { updateConfig } from 'Services/config';

const root = (pageCtx: PageContext): Promise<void> => {
  return new Promise((resolve) => {
    const queryParams = pageCtx.query;

    const { country, app, pId } = queryParams || {};

    updateConfig(country, app, pId)
      .then(resolve)
      .catch((err) => {
        setupInitializationErrorLogic(resolve, err.status, err.code);
      });
  });
};

const setupInitializationErrorLogic = (
  resolve: () => void,
  errorStatus: number,
  queryString?: string
) => {
  if (store) {
    store.dispatch({ type: INITIALIZATION_ERROR, initErrorStatus: errorStatus, queryString });
  }

  resolve();
};

const setupLoginLogic = (resolve: () => void, pageCtx: PageContext) => {
  let url = '/login';

  if (pageCtx.querystring) {
    url = url.concat(`?${pageCtx.querystring}`);
  }

  pageCtx.page.redirect(url);

  resolve();
};

const protectedPageAction = (pageCtx: PageContext) => {
  return new Promise<void>((resolve) => {
    const authSession = getAuthSession();

    if (!authSession) {
      setupLoginLogic(resolve, pageCtx);
      return;
    }

    const queryParams = pageCtx.query;

    const { country: countryParam, app, pId } = queryParams || {};

    const country = countryParam ?? authSession.user.country;

    updateConfig(country, app, pId)
      .then(() => {
        store.dispatch({ type: AUTH_SESSION, authSession });

        resolve();
      })
      .catch((err) => {
        setupInitializationErrorLogic(resolve, err.status, err.code);
      });
  });
};

// Create your paths that you want to handle here
// you want to most specific earlier and the least specific later
// Note: if one action throws an error (calls promise reject) then the handlePaths
// will move onto the next matching route
const routeActions: Array<routeAction> = [
  {
    regex: [/^\/connections/i, /^\/connection-details/i, /^\/add-connection/i],
    action: protectedPageAction,
  },
  {
    regex: [/^\.*/],
    action: root,
  },
];

type routeAction = {
  regex: Array<RegExp>;
  action: routeActionFn;
};

type routeActionFn = (ctx: PageContext) => Promise<void>;

export const handlePaths = (ctx: PageContext): Promise<void> => {
  const doActions: Array<routeActionFn> = [];

  for (const check of routeActions) {
    for (const r of check.regex) {
      if (ctx.canonicalPath.match(r)) {
        // Add valid actions to chain later
        doActions.push(check.action);
      }
    }
  }

  // Start with a rejected promise
  let p = Promise.reject<void>();

  // Build a negative promise chain that will try the next action if the previous one failed
  // Once an action succeeds the chain ends and the app starts
  for (let i = 0; i < doActions.length; i++) {
    p = p.catch(() => doActions[i](ctx));
  }

  return p;
};
