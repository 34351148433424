import React, { useEffect, useState } from 'react';

import {
  Divider,
  HStack,
  LoadingSpinner,
  Stack,
  Text,
  VStack,
} from '@adc/parallax-component-library';
import { IconInfo, IconLinkArrow12x24 } from '@adc/parallax-icons';
import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useApiError } from 'Hooks';

import { IconButton, Image, Link } from 'Components/utility';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';
import { store } from 'Utilities/store';

import { getConnections, getPendingConnections } from 'Services/llu';

const LLUCardConnection: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { showApiErrorModal } = useApiError();

  const [pendingConnectionInvitations, setPendingConnectionInvitations] = useState<
    LLUPendingConnectionInvitation[]
  >([]);

  const [connections, setConnections] = useState<LLUConnection[]>([]);

  useEffect(() => {
    const loadLLUConnections = async () => {
      try {
        setLoading(true);

        const { user } = store.getState().auth;
        const invitations = await getPendingConnections(user.id);
        const connections = await getConnections();

        setPendingConnectionInvitations(invitations);
        setConnections(connections);
      } catch (err) {
        const error = err as AxiosError;

        if (error.status === ApiStatus.UNAUTHORIZED) {
          logout();
          return;
        }

        showApiErrorModal();
      } finally {
        setLoading(false);
      }
    };

    loadLLUConnections();
  }, [showApiErrorModal]);

  return (
    <Stack margin="$4" padding="$4" backgroundColor="$surface.container" border="1.5" shadow="$-1">
      <HStack justifyContent="space-between" marginBottom="$4">
        <Image src={i18n.t('Global.microcopy.common.libreLinkUpLogo')} testID="LibreLinkUp.logo" />
        <IconButton
          testID="LibreLinkUp.icon-info-btn"
          onPress={() => window.open('https://www.librelinkup.com')}
        >
          <IconInfo color="$interactive.tertiary.enabled.text" testID="LibreLinkUp.icon-info" />
        </IconButton>
      </HStack>
      <VStack>
        {loading ? (
          <Stack width="100%" display="flex" alignItems="center">
            <LoadingSpinner testID="LibreLinkUp.spinner" />
          </Stack>
        ) : (
          <>
            {connections.length > 0 || pendingConnectionInvitations.length > 0 ? (
              <VStack marginTop="$4" style={{ cursor: 'pointer' }}>
                {connections.map((connection) => {
                  return (
                    <VStack
                      key={connection.ConnectionID}
                      marginBottom="$4"
                      onPress={() =>
                        mediator.publish('router:navigate', {
                          url: '/llu-connection',
                          data: { connection },
                        })
                      }
                    >
                      <HStack justifyContent="space-between">
                        <Text
                          testID="LibreLinkUp.name"
                          fontWeight="$bodyBase.medium"
                          fontSize="$bodyBase.medium"
                          color="$text.100"
                          width="90%"
                        >
                          {`${connection.CaregiverFirstName} ${connection.CaregiverLastName}`}
                        </Text>
                        <IconLinkArrow12x24
                          testID="LibreLinkUp.arrow-icon"
                          color="$interactive.tertiary.enabled.text"
                        />
                      </HStack>
                      <Divider marginTop="$4" borderColor="$neutral.40" />
                    </VStack>
                  );
                })}
                {pendingConnectionInvitations.map((invitation) => {
                  return (
                    <VStack
                      key={invitation.id}
                      marginBottom="$4"
                      onPress={() =>
                        mediator.publish('router:navigate', {
                          url: '/llu-pending-connection',
                          data: { pendingConnectionInvitation: invitation },
                        })
                      }
                    >
                      <HStack justifyContent="space-between">
                        <Text
                          testID="LibreLinkUp.name"
                          fontWeight="$bodyBase.medium"
                          fontSize="$bodyBase.medium"
                          color="$text.100"
                          width="90%"
                        >
                          {`${invitation.receiver.firstName} ${invitation.receiver.lastName}`}
                        </Text>
                        <IconLinkArrow12x24
                          testID="LibreLinkUp.arrow-icon"
                          color="$interactive.tertiary.enabled.text"
                        />
                      </HStack>
                      <Text
                        color="$text.60"
                        fontSize="$bodySmall.default"
                        fontWeight="$bodySmall.default"
                        testID="LibreLinkUp.connection-pending-label"
                      >
                        {i18n.t('Connections.content.addConnection.secondaryText')}
                      </Text>
                      <Divider marginTop="$4" borderColor="$neutral.40" />
                    </VStack>
                  );
                })}
              </VStack>
            ) : (
              <Stack>
                <Text marginVertical="$4" testID="LibreLinkUp.message">
                  {i18n.t('Connections.content.addConnection.primaryText')}
                </Text>
                <Divider marginBottom="$4" borderColor="$neutral.40" />
              </Stack>
            )}
            <Link
              disabled={pendingConnectionInvitations.length + connections.length === 20}
              testID="LibreLinkUp.addConnection-btn"
              onPress={() => mediator.publish('router:navigate', '/llu-add-connection')}
            >
              {i18n.t<string>('Connections.content.addConnection.links.addConnectionBtn.label')}
            </Link>
          </>
        )}
      </VStack>
    </Stack>
  );
};

export default LLUCardConnection;
