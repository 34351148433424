import React, { useState } from 'react';

import { Divider, LoadingSpinner, Stack, Text, useToast } from '@adc/parallax-component-library';
import { AxiosError } from 'axios';

import { ApiStatus } from 'Enums';

import { useApiError, useConnection } from 'Hooks';

import { Link, Main, TitleActionBar } from 'Components/utility';

import { cutString } from 'Utilities/dataHelpers';
import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';

import { removeConnection } from 'Services/llu';

type Props = {
  data: { connection: LLUConnection };
};

const LLUConnectionDetails: React.FC<Props> = ({ data: { connection } }) => {
  const [loading, setLoading] = useState(false);

  const { showStopSharingModal } = useConnection();

  const { showApiErrorModal } = useApiError();

  const { showToast } = useToast();

  const onStopSharing = () => {
    showStopSharingModal(async () => {
      try {
        setLoading(true);
        await removeConnection(connection.ConnectionID);
        showToast({
          message: i18n.t('Global.modals.removeConnection.connectionRemoved'),
          'aria-label': i18n.t('Global.modals.removeConnection.connectionRemoved'),
        });
        mediator.publish('router:navigate', '/connections');
      } catch (err) {
        const error = err as AxiosError;

        if (error.status === ApiStatus.UNAUTHORIZED) {
          logout();
          return;
        }

        showApiErrorModal();
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Main>
      <TitleActionBar
        testID="ConnectedCaregiver"
        title={`${cutString(connection.CaregiverFirstName, 10)} ${cutString(
          connection.CaregiverLastName,
          10
        )}`}
      />
      <Stack
        margin="$4"
        padding="$4"
        backgroundColor="$surface.container"
        border="1.5"
        shadow="$-1"
      >
        <Text
          testID="ConnectedCaregiver.title"
          color="$text.100"
          fontWeight="$h3.strong"
          fontSize="$h3.strong"
        >
          {i18n.t('Connections.content.connectedCaregiver.primaryText')}
        </Text>
        <Text
          testID="ConnectedCaregiver.bodyText"
          marginTop="$2"
          color="$text.100"
          fontWeight="$bodyBase.default"
          fontSize="$bodyBase.default"
        >
          {i18n.t('Connections.content.connectedCaregiver.secondaryText')}
        </Text>
        <Text
          testID="ConnectedCaregiver.email-label"
          marginTop="$8"
          color="$text.60"
          fontWeight="$bodySmall.default"
          fontSize="$bodySmall.default"
        >
          {i18n.t('Connections.content.connectedCaregiver.bodyText')}
        </Text>
        <Text
          testID="ConnectedCaregiver.email"
          color="$text.100"
          fontWeight="$bodyBase.medium"
          fontSize="$bodyBase.medium"
        >
          {connection.CaregiverEmail}
        </Text>
        <Divider marginVertical="$4" borderColor="$neutral.40" />
        {loading ? (
          <Stack width="100%" display="flex" alignItems="center">
            <LoadingSpinner />
          </Stack>
        ) : (
          <Link
            testID="ConnectedCaregiver.stopSharing-btn"
            onPress={onStopSharing}
            textColor="$support.danger.default"
          >
            {i18n.t<string>('Connections.content.connectedCaregiver.links.stopSharing.label')}
          </Link>
        )}
      </Stack>
    </Main>
  );
};

export default LLUConnectionDetails;
